import React, { useState } from 'react';
import { Grid, Card, CardMedia, CardContent, Container, Typography, Button, Box, CardActionArea } from '@mui/material';
import locations from './data/launch-sites.json'; // Adjust the path as necessary

const GridComponent = () => {
  const [displayedItems, setDisplayedItems] = useState(locations.slice(0, 12));
  const [hovered, setHovered] = useState(null); // State to manage hover

  const handleLoadMore = () => {
    const nextItems = locations.slice(displayedItems.length, displayedItems.length + 12);
    setDisplayedItems(currentItems => [...currentItems, ...nextItems]);
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={5} style={{ marginTop: "20px" }}>
        {displayedItems.map((location, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card
              sx={{ maxWidth: 345, height: 300, position: 'relative' }} // Set position to relative
              style={{ marginBottom: "30px" }}
              onMouseEnter={() => setHovered(index)}
              onMouseLeave={() => setHovered(null)}
            >
              <CardActionArea style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                {hovered === index && (
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(255, 255, 255, 0.9)', // 50% red background
                      color: 'black', // Make font color black
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'flex-start', // Align content to the left
                      padding: '10px',
                      overflowX: 'hidden',
                      overflowY: 'auto', // In case the content overflows
                      zIndex: 2, // Ensure it overlays the image and content
                    }}
                  >
                    <Typography variant="body2" component="div" style={{ textAlign: 'left', width: '100%' }}>Features:</Typography>
                    <ul style={{ 
                        textAlign: 'left', 
                        width: '100%' }}>
                      {location.Features.map((feature, featureIndex) => (
                        <li key={featureIndex} style={{ textAlign: 'left' }}>{feature}</li>
                      ))}
                    </ul>
                  </div>
                )}
                <CardMedia
                  component="img"
                  height="140"
                  image={location["Featured Image"]}
                  alt={location.Name}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {location.City}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {location.Name}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      {displayedItems.length < locations.length && (
        <Box display="flex" justifyContent="center" marginTop="20px">
          <Button variant="contained" style={{ marginTop: '20px' }} onClick={handleLoadMore}>Load More</Button>
        </Box>
      )}
    </Container>
  );
};

export default GridComponent;
