import React from 'react';
import data from './data/launch-sites.json';
import { useDataContext } from './DataContext';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import DirectionsIcon from '@mui/icons-material/Directions';


function LaunchSiteDetail() {
    const { selectedData, selectData } = useDataContext();
    const selectedLaunchSite = data.find(launchSite => launchSite['Name'] === selectedData);

    if (!selectedLaunchSite) return null; // No site selected, don't render anything

    const handleOpenGoogleMaps = () => {
        const latitude = selectedLaunchSite['Latitude'];
        const longitude = selectedLaunchSite['Longitude'];
        window.open(`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`, '_blank');
    };

    return (
        <div style={{
            position: 'absolute', // or 'absolute'
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: adds a semi-transparent overlay
            zIndex: 2, // Ensure it's above other content
        }}>
            <Card variant="outlined" sx={{ maxWidth: 345, position: 'relative' }}>
                <IconButton 
                    aria-label="close" 
                    onClick={() => selectData(null)}
                    sx={{ 
                        position: 'absolute', 
                        top: 0, 
                        right: 0, 
                        color: 'white',
                        backgroundColor: 'rgba(255, 255, 255, 0.6)', 
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.8)', 
                        },
                        borderRadius: '50%' 
                    }}
                >
                    <CloseIcon />
                </IconButton>
                {/* Directions Icon Button */}
                <IconButton
                    aria-label="directions"
                    onClick={handleOpenGoogleMaps}
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        color: 'white',
                        backgroundColor: 'rgba(26, 115, 232)',
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        },
                        borderRadius: '50%'
                    }}
                >
                    <DirectionsIcon />
                </IconButton>
                <CardMedia
                    component="img"
                    height="140"
                    image={selectedLaunchSite['Featured Image']}
                    alt={selectedLaunchSite['Site Name']}
                    loading="lazy"
                />
                <CardContent sx={{
                     backgroundColor: '#f5f5f5' }}>
                    <Typography gutterBottom variant="h5" component="div">
                        {selectedLaunchSite['Site Name']}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {selectedLaunchSite['City']}
                    </Typography>
                    <hr />
                    <Typography variant="body2">
                        {selectedLaunchSite['Description']}
                    </Typography>
                     <hr />
                        <Typography variant="h6" component="div">
                            Features + Activities
                        </Typography>
                        <div style={{ display: 'grid', 
                                    flexWrap: 'wrap', 
                                    gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))', // Adjust column width as needed
                                    gap: '10px' }}>
                            {selectedLaunchSite.Features.map((feature, featureIndex) => (
                                <div key={featureIndex} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <CheckIcon />
                                    {feature}
                                </div>
                            ))}
                        </div>
                </CardContent>
            </Card>
        </div>
    );
}

export default LaunchSiteDetail;
