// DataContext.js
import { createContext, useContext, useState } from 'react';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [selectedData, setSelectedData] = useState(null);

  const selectData = (data) => {
    setSelectedData(data);
  };

  return (
    <DataContext.Provider value={{ selectedData, selectData }}>
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => {
  return useContext(DataContext);
};

export default DataContext;;