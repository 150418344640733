import mapboxgl from 'mapbox-gl';
import React, { useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import geoJson from './data/florida-launch-sites-geojson.json';
import './Map.css';
import { useDataContext } from './DataContext';

mapboxgl.accessToken =
  'pk.eyJ1Ijoia2FydGhpay10bmwiLCJhIjoiY2xzbTR0cWRnMG0xMzJrcGRhYmtyemZpdCJ9.RvZeWGr1eScfH8M03jZyow';

const Marker = ({ onClick, children, feature }) => {
  const _onClick = () => {
    onClick(feature.properties.Name);
  };

  return (
    <button onClick={_onClick} className="marker">
      {children}
    </button>
  );
};

const Map = () => {
  const {selectData} = useDataContext();
  console.log('selected data',selectData);
  const handleMapClick = (data) => {
    selectData(data);
  };

  const mapContainerRef = useRef(null);

  // Initialize map when component mounts
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/karthik-tnl/clwoebuvw049z01nxbsvv8twf',
      center: [-81.65, 28.84],
      zoom: 5,
    });

    // Render custom marker components
    geoJson.features.forEach((feature) => {
      // Create a React ref
      const ref = React.createRef();
      // Create a new DOM node and save it to the React ref
      ref.current = document.createElement('div');
      // Render a Marker Component on our new DOM node
      createRoot(ref.current).render(
        <Marker onClick={markerClicked} feature={feature} />
      );

      // Create a Mapbox Marker at our new DOM node
      new mapboxgl.Marker(ref.current)
        .setLngLat(feature.geometry.coordinates)
        .addTo(map);
    });

    // Add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), 'top-right');

    // Clean up on unmount
    return () => map.remove();
  }, []);

  const markerClicked = (Name) => {
    handleMapClick(Name)
  };

  return <div className="map-container" ref={mapContainerRef} />;
};

export default Map;