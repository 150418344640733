import Navbar from './Navbar';
import Map from './Map';
import LaunchSiteDetail from './LaunchSiteDetail';
import { DataProvider } from './DataContext';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GridComponent from './GridComponent';
import MapHeaderComponent from './MapHeaderComponent';
import MapHeroComponent from './MapHeroComponent'

function App() {
    return (
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<DataProvider>
            <Map / >
            <LaunchSiteDetail />
          </DataProvider>} />
            <Route path="/launchsite" element={<GridComponent />} />
          </Routes>
          
        </div>
      </Router>
    );
}

export default App;
